import React, { Suspense } from 'react';
import { HashRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import './App.css';

const Maps = React.lazy(() => import('./pages/Maps'));
const Polls = React.lazy(() => import('./pages/Polls'));
const Graphs = React.lazy(() => import('./pages/Graphs'));
const About = React.lazy(() => import('./pages/About'));
const Legacy = React.lazy(() => import('./pages/Legacy'));

const NotFound: React.FC = () => (
  <div className="not-found">
    <h1>404 - Page Not Found</h1>
    <Link to="/maps"><button>Return to Maps</button></Link>
  </div>
);

const App: React.FC = () => {
  return (
    <Router>
      <div className="app-container">
        {/* Top Header */}
        <header className="top-header stripe">
          <div className="left-box"></div>
          <svg className='left-curve' xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 13 12">
            <path d="M -2 0 L 11 -0 C 10 -0 8 -0 7 4 L 6 8 C 5 12 3 12 2 12 L -2 12 L -2 0" />
          </svg>
          <div className="e-m-title"><h1>ElectionMap.lol</h1></div>
          <svg className='right-curve' xmlns="http://www.w3.org/2000/svg" viewBox="-11 -12 13 12">
            <path d="M 2 0 L -11 0 C -10 0 -8 0 -7 -4 L -6 -8 C -5 -12 -3 -12 -2 -12 L 2 -12 L 2 0" />
          </svg>
          <div className="right-box"></div>
        </header>

        {/* Navigation Header */}
        <header className="nav-header">
          <Link to="/maps"><div className='nav-button'><p>Maps</p></div></Link>
          <Link to="/polls"><div className='nav-button'><p>Polls</p></div></Link>
          <Link to="/graphs"><div className='nav-button'><p>Graphs</p></div></Link>
          <Link to="/about"><div className='nav-button'><p>About</p></div></Link>
          <Link to="/legacy"><div className='nav-button'><p>Legacy</p></div></Link>
        </header>

        {/* Main Content with Routes and Lazy Loading */}
        <div className="main-content">
          <Suspense fallback={<p>Loading...</p>}>
            <Routes>
              {/* Redirect root to /maps */}
              <Route path="/" element={<Navigate to="/maps" replace />} />
              <Route path="/maps" element={<Maps layoutClass="maps-columns" />} />
              <Route path="/polls" element={<Polls layoutClass="two-column-layout" />} />
              <Route path="/graphs" element={<Graphs layoutClass="two-column-layout" />} />
              <Route path="/about" element={<About layoutClass="about-columns" />} />
              <Route path="/legacy" element={<Legacy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </Router>
  );
}

export default App;
